<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教研中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/teacherRecord' }"
          >教师录单</el-breadcrumb-item
        >
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info" v-loading="editLoading">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="课程名称" label-position="left" class="input">
          <el-input style="width: 200px" disabled v-model="form.chapter_name">
          </el-input>
        </el-form-item>
        <el-form-item label="章节名称" label-position="left" class="input">
          <el-input style="width: 200px" disabled v-model="form.course_name">
          </el-input>
        </el-form-item>
<!--        <el-form-item label="小节名称" label-position="left" class="input">-->
<!--          <el-input-->
<!--            v-if="form.classType == 2"-->
<!--            style="width: 200px"-->
<!--            disabled-->
<!--            v-model="form.class_name"-->
<!--          >-->
<!--          </el-input>-->
<!--          <el-input-->
<!--            v-else-->
<!--            style="width: 200px"-->
<!--            disabled-->
<!--            v-model="form.file_name"-->
<!--          >-->
<!--          </el-input>-->
<!--        </el-form-item>-->
      
        <el-form-item label="图片" label-position="left">
          <pasteImage
            @onSuccess="onSuccess"
            :uploadPath="uploadPath"
            @onRomove="onRemove"
            :picArray="form.pics"
          ></pasteImage>
        </el-form-item>
        <el-form-item
          label="课程类型："
          label-position="left"
          class="input"
          style="width: 300px"
        >
          <el-input
            v-if="form.course_type === 1"
            disabled
            value="付费直播"
          ></el-input>
          <el-input
            v-else-if="form.course_type === 2"
            disabled
            value="免费直播"
          ></el-input>
          <el-input
            v-else-if="form.course_type === 3"
            disabled
            value="体验直播"
          ></el-input>
          <el-input v-else value="录播课" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="小节类型："
          label-position="left"
          class="input"
          style="width: 300px"
        >
          <el-input
            v-if="form.class_type === 1"
            disabled
            value="课件"
          ></el-input>
          <el-input v-else disabled value="课时"></el-input>
        </el-form-item>

      
        <el-form-item
          label="数量/时长"
          label-position="left"
          class="input"
        >
          <el-input
            style="width: 200px"
            v-model="form.workload"
            disabled
            placeholder="请输入数量/时长"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="desc">
          <el-input
            style="width: 40%"
            type="textarea"
            :rows="10"
            maxlength="255"
            show-word-limit
            placeholder="请输入内容"
            v-model="form.desc"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            @click="onSubmit"
            >保存</el-button
          >
          <router-link to="/teacherRecord" class="router-link"
            ><el-button style="margin-left: 10px" size="small"
              >取消</el-button
            ></router-link
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import pasteImage from "../../../components/pasteImage/index";
export default {
  name: "add",
  components: { pasteImage },
  data() {
    return {
      uploadPath: "teacherRecord",
      loading: false,
      editLoading: false,
      form: {
        id: 0,
        type: "",
        classType: "",
        workload: "",
        desc: "",
      },
      types: [
        {
          value: 1,
          label: "课件",
        },
        {
          value: 2,
          label: "课时",
        },
      ],
      classTypes: [
        {
          value: 1,
          label: "付费直播",
        },
        {
          value: 2,
          label: "免费直播",
        },
        {
          value: 3,
          label: "体验直播",
        },
        {
          value: 4,
          label: "录播课",
        },
      ],
      rules: {
        type: [{ required: true, message: "类型必选", trigger: "blur" }],
        classType: [
          { required: true, message: "上课类型必选", trigger: "blur" },
        ],
        workload: [
          { required: true, message: "数量/时长必须", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("teacherRecord", ["addTeacherRecord", "getTeacherRecord"]),
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.saveTeacherRecord();
        } else {
          return false;
        }
      });
    },
    async saveTeacherRecord() {
      let _this = this;
      this.loading = true;
      await this.addTeacherRecord(this.form)
        .then((res) => {
          this.loading = false;
          if (res.ret == 0) {
            _this.$message.success("编辑成功！");
            _this.$router.push("/teacherRecord");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    selectType(val) {
      this.form.type = val;
    },
    selectClassType(val) {
      this.form.classType = val;
    },
    onSuccess(path) {
      var pics = path + "";
      var that = this;
      var pathList = pics.split(",");
      var relPath = pathList[pathList.length - 1];
      that.form.pics.push(relPath);
    },
    // 图片移除
    onRemove(index, file) {
      this.form.pics.splice(index, 1);
    },
  },
  async mounted() {
    this.form.id = this.$route.params.id;
    this.editLoading = true;
    const { res_info, data } = await this.getTeacherRecord(this.form.id);
    if (res_info !== "ok") return;
    this.form.id = data.id;
    this.form.type = data.type;
    this.form.classType = data.class_type;
    this.form.workload = data.workload;
    this.form.desc = data.desc;
    this.form.pics = data.pics;
    this.form.chapter_name = data.chapter_name;
    this.form.class_name = data.class_name;
    this.form.course_name = data.course_name;
    this.form.file_name = data.file_name;
    this.editLoading = false;
  },
};
</script>

<style>
.el-form-item__label {
  text-align: justify;
}
</style>
